// Main Functional Component
function Footer() {

    // Render Function
    return (
        <div className='footer'>
            <div className='footer-text'>
                <div>2024</div>
            </div>
        </div>
    );
}

export default Footer;